import(/* webpackMode: "eager" */ "/home/runner/work/rimo-frontend/rimo-frontend/node_modules/.pnpm/next@14.2.4_@babel+core@7.23.3_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/client/components/app-router.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/rimo-frontend/rimo-frontend/node_modules/.pnpm/next@14.2.4_@babel+core@7.23.3_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/rimo-frontend/rimo-frontend/node_modules/.pnpm/next@14.2.4_@babel+core@7.23.3_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/rimo-frontend/rimo-frontend/node_modules/.pnpm/next@14.2.4_@babel+core@7.23.3_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/rimo-frontend/rimo-frontend/node_modules/.pnpm/next@14.2.4_@babel+core@7.23.3_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/client/components/not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/rimo-frontend/rimo-frontend/node_modules/.pnpm/next@14.2.4_@babel+core@7.23.3_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/client/components/render-from-template-context.js");
